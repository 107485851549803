import * as bootstrap from "bootstrap";
import "fullview";

$(function () {
    if (document.location.href.indexOf("#") > -1) {
        document.location.href = document.location.href.split("#")[0];
    }

    if (window.innerWidth < 1025) {
        $(".auth-input-number").attr("type", "number");
    }

    function initFullView() {
        const fw = $("#fullview").fullView({
            dots: true,
            dotsPosition: "right",
            navbar: "#navbar",
            dotsTooltips: true,
            onScrollStart: function (currentView, destinationView, direction) {
                if (currentView.index() === 0 && direction === "down") {
                    $(currentView).removeClass("init");
                }
                if (destinationView.index() === 0 && direction === "up") {
                    $(destinationView).addClass("init");
                }
                $(currentView).addClass("leaving-" + direction);
                $(destinationView).addClass("entring-" + direction);
            },
            onScrollEnd: function (currentView, previousView, direction) {
                $(previousView).removeClass("leaving-" + direction);
                $(currentView).removeClass("entring-" + direction);
            },
        });

        return fw;
    }

    initFullView();

    let width = $(window).width();

    $(window).on("resize", function () {
        if (width !== $(window).width()) {
            document.location.href = document.location.href.split("#")[0];
            width = $(window).width();
        }
    });

    if (localStorage.getItem("accepted") === "true") {
        submitSuccessContent();
        $("#submit-content").hide();
        $("#submit-success").show();
    }

    $(".auth-input-number").on("keyup paste", function (e) {
        this.value = this.value.replace(/[^0-9]/g, "");
        const self = $(this);
        if (self.val().length === 2) {
            self.parent().next().children(".auth-input-number").focus();
        } else if (self.val().length === 0 && e.key === "Backspace") {
            if ($(".auth-response").hasClass("shown")) {
                $(".auth-response").removeClass("shown");
            }
            self.parent().prev().children(".auth-input-number").focus();
        }
    });

    $(".auth-input-number").on("input", function (e) {
        let digits = 0;
        let vals = "";
        $(".auth-input-number").each(function () {
            const self = $(this);
            const value = self.val();
            digits += value.length;
            vals += value;
        });
        if (digits === 6) {
            if (vals === "250622") {
                setTimeout(function () {
                    window.location.href = "/auth";
                }, 400);
            } else {
                $(".auth-input-number").each(function () {
                    $(this).toggleClass("error");
                });
                $(".auth-response")
                    .text("Bitte geben Sie das korrekte Datum ein.")
                    .addClass("shown");
            }
        }
    });

    const loadingIndicator = `<div style="width: 1rem; height: 1rem; border-width: 0.15em; display: none;" class="ms-3 spinner-border text-light loading-indicator" role="status">
    <span class="visually-hidden">Loading...</span>
</div>`;

    $(".type-selector").on("change", function () {
        const self = $(this);
        const value = self.val();

        if (value === "cancel") {
            $(".accept-form-information").show(150);
            $(".accept-button").html("Absagen " + loadingIndicator);
            $(".submit_type").text("Absagen");
            $(".accept-form-description").text(
                "Schade, dass du nicht an meiner Geburtstagsfeier teilnehmen kannst. Falls du deine Entscheidung ändern möchtest, kannst du mir gerne eine E-Mail an fragen@regina-70.de schicken."
            );
            $(".accept-form-infos").hide();
        } else if (value === "surprise") {
            $(".accept-form-information").show(150);
            $(".accept-button").html("Zusagen " + loadingIndicator);
            $(".accept-form-location").html(
                "Sperberstraße <br> 53881 Euskirchen"
            );
            $(".accept-form-time-title").text("Beginn");
            $(".accept-form-time").text("12:30");
            $(".submit_type").text("Überraschungsevent");
            $(".accept-form-description").html(
                "Beginn ist um 12:30 auf der Burg Flamersheim in Euskirchen. <b>Legere Kleidung und Wechselkleidung</b> beachten."
            );
            $(".accept-form-infos").show();
        } else if (value === "surprise-bus") {
            $(".accept-form-information").show(150);
            $(".accept-button").html("Zusagen " + loadingIndicator);
            $(".accept-form-location").html(
                "Aachener Str. 999 <br> 50933 Köln"
            );
            $(".accept-form-time-title").text("Abfahrt");
            $(".accept-form-time").text("11:30");
            $(".submit_type").text("Überraschungsevent");
            $(".accept-form-description").html(
                "Abfahrt ist um 11:30 Uhr am <b>Parkplatz</b> vor dem <b>Müngersdorfer Stadion</b> an der Jahnwiese (Parkplätze vorhanden). Für alle, die mitfahren, steht ab <b>23:30 Uhr</b> ein <b>Bus für den Rücktransport</b> bereit. <b>Legere Kleidung und Wechselkleidung</b> beachten."
            );
            $(".accept-form-infos").show();
        } else if (value === "normal") {
            $(".accept-form-information").show(150);
            $(".accept-button").html("Zusagen " + loadingIndicator);
            $(".accept-form-location").html(
                "Sperberstraße <br> 53881 Euskirchen"
            );
            $(".accept-form-time-title").text("Beginn");
            $(".accept-form-time").text("15:30");
            $(".submit_type").text("Für Alle");
            $(".accept-form-description").html(
                "Die Feier zu meinem <b>70. Geburtstag</b> beginnt am <b>25. Juni um 15:30 Uhr</b> auf der <b>Burg Flamersheim.</b> Los geht es mit der Siegerehrung des zuvor stattfindenden Überraschungsevents. Für <b>Essen, Getränke und schönes Ambiente</b> ist natürlich gesorgt."
            );
            $(".accept-form-infos").show();
        } else {
            $(".accept-form-information").hide(100);
            $(".accept-button").html("Zusagen " + loadingIndicator);
            $(".submit_type").text("Bitte Auswählen");
            $(".accept-form-location").html("XXX");
            $(".accept-form-time-title").text("Beginn");
            $(".accept-form-time").text("00:00");
            $(".accept-form-description").text(
                "Bitte wähle aus ob und wie du am Geburtstag teilnehmen möchtest."
            );
            $(".accept-form-infos").show();
        }
    });

    const authentication = $("#authentication");

    if (authentication.length > 0) {
        authentication.addClass("init");
    }

    function submitSuccessContent(fromSubmit = false) {
        let peopleCount, type, firstname, lastname;

        if (fromSubmit) {
            if (window.innerWidth > 768) {
                peopleCount = $("#people_count").val();
                type = $("#type").val();
                firstname = $("#firstname").val();
                lastname = $("#lastname").val();
            } else {
                peopleCount = $("#people_count_mobile").val();
                type = $("#type_mobile").val();
                firstname = $("#firstname_mobile").val();
                lastname = $("#lastname_mobile").val();
            }
            localStorage.setItem("accepted", true);
            localStorage.setItem("firstname", firstname);
            localStorage.setItem("lastname", lastname);
            localStorage.setItem("type", type);
            localStorage.setItem("people_count", peopleCount);
        } else {
            peopleCount = localStorage.getItem("people_count");
            type = localStorage.getItem("type");
            firstname = localStorage.getItem("firstname");
            lastname = localStorage.getItem("lastname");
        }

        if (
            type === "surprise" ||
            type === "surprise-bus" ||
            type === "normal"
        ) {
            $("#ics-download").attr(
                "href",
                `/storage/${firstname.trim()}-${lastname.trim()}.ics`.toLowerCase()
            );
            $("#submit-success-content").html(`
            <h1 style="font-family: 'PT Serif', serif; text-transform: none; letter-spacing: 0; font-weight: 400;">Vielen Dank ${firstname}, ich freu mich darauf mit dir ${
                peopleCount > 1 ? "und deiner Begleitung" : ""
            } meinen Geburtstag Feiern zu dürfen.</h1>
            <p class="lead mt-4 pt-2">
            Hier kannst du dir die Daten in deinen Kalender als Termin hinterlegen. Alternativ können dir die Daten auch als E-Mail geschickt werden.
            </p>
            `);
            return;
        }

        $(".only-if-accepted").hide();

        $("#submit-success-content").html(`
            <h1 style="font-family: 'PT Serif', serif; text-transform: none; letter-spacing: 0; font-weight: 400;">Schade ${firstname}, dass du nicht an meinem Geburtstag teilnehmen kannst.</h1>
            <p class="lead mt-4 pt-2">
            Falls du deine Entscheidung ändern möchtest, kannst du mir gerne eine E-Mail an fragen@regina-70.de schicken.
            </p>
        `);
    }

    $(".accept-form").on("submit", function (e) {
        $(".loading-indicator").show();
        $(".accept-response").text("");

        e.preventDefault();
        const self = $(this);

        const data = self.serialize();

        $(".error-message").remove();

        setTimeout(function () {
            $.ajax({
                url: self.attr("action"),
                type: "POST",
                data: data,
                success: function (response) {
                    if (!response.success) {
                        $(".accept-response").text(
                            "Es sind Fehler aufgetreten. Bitte versuche es erneut."
                        );
                        Object.entries(response.errors).forEach(
                            ([key, value]) => {
                                $(
                                    "#" +
                                        key +
                                        (window.innerWidth <= 768
                                            ? "_mobile"
                                            : "")
                                ).toggleClass("error");
                                $(
                                    "#" +
                                        key +
                                        (window.innerWidth <= 768
                                            ? "_mobile"
                                            : "")
                                )
                                    .parent()
                                    .append(
                                        $(
                                            "<span class='error-message d-inline-block mt-3 text-danger'>" +
                                                value +
                                                "</span>"
                                        )
                                    );
                            }
                        );
                        $(".loading-indicator").hide();
                        return;
                    }

                    if (window.innerWidth <= 768) {
                        const confirmModal =
                            bootstrap.Modal.getInstance("#confirmModal");
                        confirmModal.hide();
                        $("#scroll-to-last").trigger("click");
                    }

                    $("#submit-content").hide();
                    $(".loading-indicator").hide();
                    submitSuccessContent(true);
                    $("#submit-success").show();
                },
                error: function (response) {
                    $(".loading-indicator").hide();
                    console.log(response);
                },
            });
        }, 300);
    });

    $("#sendEmailForm").on("submit", function (e) {
        e.preventDefault();

        const self = $(this);

        const email = $("#email").val();
        const peopleCount = localStorage.getItem("people_count");
        const type = localStorage.getItem("type");
        const firstname = localStorage.getItem("firstname");
        const lastname = localStorage.getItem("lastname");

        const csrfToken = self.find("input[name='_token']").val();

        $(".loading-indicator").show();

        const payload = {
            email,
            people_count: peopleCount,
            type,
            firstname,
            lastname,
            _token: csrfToken,
        };

        setTimeout(function () {
            $.ajax({
                url: self.attr("action"),
                type: "POST",
                data: payload,
                success: function (response) {
                    if (!response.success) {
                        $(".loading-indicator").hide();

                        // $("#accept-response").text(
                        //     "Es sind Fehler aufgetreten. Bitte versuche es erneut."
                        // );
                        Object.entries(response.errors).forEach(
                            ([key, value]) => {
                                $("#" + key).toggleClass("error");
                                $("#" + key)
                                    .parent()
                                    .append(
                                        $(
                                            "<span class='error-message d-inline-block mt-3 text-danger'>" +
                                                value +
                                                "</span>"
                                        )
                                    );
                            }
                        );
                        // $("#loading-indicator").hide();
                        return;
                    }
                    const emailModal =
                        bootstrap.Modal.getInstance("#emailModal");
                    emailModal.hide();
                    $("#scroll-to-last").trigger("click");

                    $(".loading-indicator").hide();
                },
                error: function (response) {
                    $(".loading-indicator").show();
                    console.log(response);
                },
            });
        }, 300);
    });

    const confirmModal = document.querySelector("#confirmModal");
    const emailModal = document.querySelector("#emailModal");

    if (confirmModal) {
        confirmModal.addEventListener("hidden.bs.modal", function () {
            setTimeout(function () {
                $("#scroll-to-last").trigger("click");
            }, 50);
        });
    }

    if (emailModal) {
        emailModal.addEventListener(
            "hidden.bs.modal",
            function () {
                setTimeout(function () {
                    $("#scroll-to-last").trigger("click");
                });
            },
            50
        );
    }

    setTimeout(function () {
        $("#first-slide").addClass("init");
    }, 50);
});
